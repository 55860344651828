const contentData = [
  {
    title: "Download our Chrome Extension ",
    link: "https://auth0.com/docs/connections",
    description:
      "When you download our Chrome extension you can leave remarks in all the job posts on Linkedin and we will soon add other job posting websites"
  },
  {
    title: "Leave us your feedback",
    link: "https://auth0.com/docs/multifactor-authentication",
    description:
      "We made this application for the people and we always appreciate your feedback to make it grow, our app is made to encourage feeback on job posts so any feeback is welcome "
  },
  // {
  //   title: "Anomaly Detection",
  //   link: "https://auth0.com/docs/anomaly-detection",
  //   description:
  //     "Auth0 can detect anomalies and stop malicious attempts to access your application. Anomaly detection can alert you and your users of suspicious activity, as well as block further login attempts."
  // },
  // {
  //   title: "Learn About Rules",
  //   link: "https://auth0.com/docs/rules",
  //   description:
  //     "Rules are JavaScript functions that execute when a user authenticates to your application. They run once the authentication process is complete, and you can use them to customize and extend Auth0's capabilities."
  // }
];

export default contentData;
